var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"8"}},[_c('h3',{staticClass:"text-h3 mb-6"},[_vm._v("Информация партнера")]),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('Select',{class:[_vm.$style.select, _vm.$style.item, {'mb-6': _vm.is_not_desktop}],attrs:{"value":_vm.new_project.organization_id,"items":_vm.active_org,"label":"Организация","item_value":"id","item-text":"name_organization","hide-details":"","clearable":"","blue_icon":"","background-color":"#fff","dense":"","require":"","rules":[function (v) { return _vm.is_require(v) || _vm.require_message; }]},on:{"input":function($event){return _vm.upd_field('organization_id', $event)}}}),_c('Select',{class:[_vm.$style.select, _vm.$style.item],attrs:{"value":_vm.new_project.subpartner_id,"items":_vm.subpartners,"label":"Субпартнер","item_value":"id","item-text":"name_subpartner","hide-details":"","clearable":"","blue_icon":"","background-color":"#fff","dense":""},on:{"input":function($event){return _vm.upd_field('subpartner_id', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow]},[_c('Datepicker',{class:[_vm.$style.datepicker, _vm.$style.item, _vm.$style['required-label']],attrs:{"value":_vm.new_project.shipment_at,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ],"min":_vm.get_today(),"label":"Планируемая дата отгрузки","dense":"","clearable":"","require":""},on:{"input":function($event){return _vm.upd_field('shipment_at', $event)}}}),_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_project.sale_points_quantity,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ],"label":"Количество торговых точек","hide_btns":"","dense":"","require":"","type":"number","min":0},on:{"input":function($event){return _vm.upd_field('sale_points_quantity', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow]},[_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_project.partner_contact_person,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; } ],"label":"ФИО контактного лица","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('partner_contact_person', $event)}}}),_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_mask),expression:"phone_mask"}],class:_vm.$style.item,attrs:{"value":_vm.me.telephone,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_correct_phone(v) || _vm.invalid_phone_number; }
            ],"label":"Телефон контактного лица","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('partner_contact_telephone', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow]},[_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_project.partner_contact_email,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_email(v) || _vm.invalid_email; }
            ],"label":"Email контактного лица","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('partner_contact_email', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow]},[_c('Textarea',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"vlaue":_vm.new_project.description,"label":"Описание проекта","counter":1000,"maxlength":1000,"rows":2,"require":"","rules":[function (v) { return _vm.is_require(v) || _vm.require_message; }]},on:{"input":function($event){return _vm.upd_field('description', $event)}}})],1),_c('h3',{staticClass:"text-h3 mb-6"},[_vm._v("Информация о клиенте")]),_c('div',{class:[_vm.$style.itemsRow]},[_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_project.client_name,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ],"label":"Коммерческое название клиента/ брэнда/сети","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('client_name', $event)}}}),_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_project.client_city,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ],"label":"Город","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('client_city', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow]},[_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_project.client_website,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ],"label":"Web сайт","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('client_website', $event)}}}),_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_mask),expression:"phone_mask"}],class:_vm.$style.item,attrs:{"value":_vm.new_project.client_telephone,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_correct_phone(v) || _vm.invalid_phone_number; }
            ],"label":"Телефон","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('client_telephone', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow]},[_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_project.client_email,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_email(v) || _vm.invalid_email; }
            ],"label":"Email","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('client_email', $event)}}}),_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_project.client_contact_person,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; } ],"label":"Контактное лицо","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('client_contact_person', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow]},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_mask),expression:"phone_mask"}],class:_vm.$style.item,attrs:{"value":_vm.new_project.client_contact_telephone,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_correct_phone(v) || _vm.invalid_phone_number; }
            ],"label":"Телефон контактного лица","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('client_contact_telephone', $event)}}}),_c('TextField',{class:_vm.$style.item,attrs:{"value":_vm.new_project.client_contact_email,"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_email(v) || _vm.invalid_email; }
            ],"label":"Email контактного лица","dense":"","require":""},on:{"input":function($event){return _vm.upd_field('client_contact_email', $event)}}})],1),_c('div',{class:[_vm.$style.itemsRow, 'mb-6']},[_c('Select',{class:[_vm.$style.select, _vm.$style.item, {'mb-6': _vm.is_not_desktop}],attrs:{"value":_vm.new_project.client_ids,"items":_vm.clients,"label":"Наименование клиента","item_value":"id","item-text":"name_client_company","hide-details":"","clearable":"","blue_icon":"","background-color":"#fff","dense":"","require":"","multiple":"","rules":[function (v) { return _vm.is_require(v) || _vm.require_message; }]},on:{"input":function($event){return _vm.upd_field('client_ids', $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item',{on:{"click":function($event){return _vm.toggle_selection(item.id)}}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":_vm.new_project.client_ids && _vm.new_project.client_ids.includes(item.id)},on:{"change":function($event){return _vm.toggle_selection(item.id)},"click":function($event){$event.stopPropagation();}}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name_client_company)+" ")]),_c('v-list-item-subtitle',[_c('span',{staticClass:"mr-4"},[_vm._v("ИНН: "+_vm._s(item.inn_client))]),_vm._v(" КПП: "+_vm._s(item.kpp_client)+" ")])],1)],1)]}}])}),_c('v-btn',{class:{'ml-3': !_vm.is_not_desktop},attrs:{"color":"accent darken-4","depressed":""},on:{"click":function($event){_vm.add_new_client = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Добавить нового клиента ")],1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-auto",attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")]),_c('v-btn',{attrs:{"color":"accent darken-4","depressed":"","type":"submit"}},[_vm._v(" Продолжить ")])],1)],1)],1)],1),_c('AddNewClientDialog',{on:{"submit":_vm.add_client,"close":function($event){_vm.add_new_client = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Добавление клиента ")]},proxy:true}]),model:{value:(_vm.add_new_client),callback:function ($$v) {_vm.add_new_client=$$v},expression:"add_new_client"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }